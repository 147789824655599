<template>
  <div class="pageContol listWrap templateList1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" @click="goTactics">试卷管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" v-if="!isGroupPapers">试卷列表</a>
          <i v-if="!isGroupPapers">></i>
          <a href="javascript:;" class="cur-a" v-if="stu == 'edit'&&!isGroupPapers">试卷编辑</a>
          <a href="javascript:;" class="cur-a" v-if="stu == 'view'&&!isGroupPapers">试卷预览</a>
          <a href="javascript:;" class="cur-a" v-if="stu == 'edit'&&isGroupPapers">自行组卷</a>
        </span>

      </div>
      <div class="framePage-body" style="padding-left: 0">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="tacticsRule_left">

              <el-button type="primary" size="small" style="margin: 0 0 15px 24px" @click="addBigQuestionShow()" v-if="isGroupPapers">新增大题</el-button>
              <div class="shell">
                <div class="shellItem" @click="currentActive(index)" v-for="(item,index) in paperInfo.partitionList" :key="index">
                  <div class="shellTitle">第{{ changeNumToHan(index + 1) }}大题</div>
                  <p>共有{{ item.questionNum }}小题</p>
                  <p>合计{{ item.questionScore||0 }}分</p>
                </div>
<!--                <div class="shellItem" @click="currentActive(1)">-->
<!--                  <div class="shellTitle">{{ 1 }}</div>-->
<!--                  <p>共有{{ 2 }}小题</p>-->
<!--                  <p>合计{{ 3 }}分</p>-->
<!--                </div>-->
                <!--@click="onTouch('tacticsRule_right_list' + index)"-->
              </div>

            </div>
            <div class="tacticsRule_right">
              <div class="operationControl flexdc" style="align-items: flex-start">
                <div class="searchbox" style="margin-bottom: 0.675rem">
                  <div title="试卷名称" class="searchboxItem ci-full">
                    <span class="itemLabel">试卷名称:</span>
                    <span style="text-align: left;padding: 0;">
                      <span v-if="showEditPaperName == false" style="width: auto;min-width: auto;text-align: left;padding: 0;">
                         <span style="margin-right: 8px;width: auto;min-width: auto;text-align: left;padding: 0;">{{ paperInfo.paperName }}</span>
                         <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHidePaperNameEdit(false)" :disabled="stu != 'edit'"></el-button>
                      </span>
                      <span v-else style="width: auto;min-width: auto;text-align: left;padding: 0;">
                        <span style="margin-right: 8px;width: auto;min-width: auto;text-align: left;padding: 0;"><el-input size="small" style="width:300px" v-model="paperInfo.paperName" placeholder="请输入大题名称" maxlength="50" show-word-limit></el-input></span>
                        <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHidePaperNameEdit(true)" :disabled="stu != 'edit'"></el-button>
                      </span>
                    </span>
                  </div>
                  <div title="试卷编号" class="searchboxItem ci-full">
                    <span class="itemLabel">试卷编号:</span>
                    <span style="text-align: left;padding: 0;">
                      <span v-if="showEditPaperCode == false" style="width: auto;min-width: auto;text-align: left;padding: 0;">
                         <span style="margin-right: 8px;width: auto;min-width: auto;text-align: left;padding: 0;">{{ paperInfo.paperCode }}</span>
                         <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHidePaperCodeEdit(false)" :disabled="stu != 'edit'"></el-button>
                      </span>
                      <span v-else style="width: auto;min-width: auto;text-align: left;padding: 0;">
                        <span style="margin-right: 8px;width: auto;min-width: auto;text-align: left;padding: 0;"><el-input size="small" style="width:300px" v-model="paperInfo.paperCode" placeholder="请输入试卷编号" maxlength="30" show-word-limit></el-input></span>
                        <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHidePaperCodeEdit(true)" :disabled="stu != 'edit'"></el-button>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="searchbox" style="margin-bottom: 5px">
                  <div title="题目内容" class="searchboxItem ci-full">
                    <span class="itemLabel">题目内容:</span>
                    <el-input
                        v-model="questionName"
                        type="text"
                        size="small"
                        clearable
                        placeholder="请输入题目内容"
                    />
                  </div>
                  <div title="题目类型" class="searchboxItem ci-full">
                    <span class="itemLabel">题目类型:</span>
                    <el-select
                        v-model="questionType"
                        placeholder="请选择题目类型"
                        size="small"
                        clearable
                    >
                      <el-option
                          v-for="item in QuestionTypeList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                  <el-button class="bgc-bv" style="margin: 0 10px" round @click="getPaperInfo()">查询</el-button>
                  <el-dialog
                          title="新增大题"
                          :visible.sync="dialogVisibleBigQuestion"
                          width="500px"
                          top="-200px"
                          :close-on-click-modal="false"
                          :before-close="handleCloseBigQuestion">
                      <div class="addBigQuestion">
                          <div title="大题标题" style="width: 100%;display: flex;justify-content: flex-start;align-items: center">
                            <span class="itemLabel" style="min-width: 80px">大题标题:</span>
                            <el-input v-model="bigQuestionTitle" size="small" placeholder="请输入大题标题" maxlength="30"
                                      show-word-limit ></el-input>

                          </div>
                      </div>
                      <span slot="footer" class="dialog-footer">
                        <el-button @click="handleCloseBigQuestion">取 消</el-button>
                        <el-button type="primary" @click="addBigQuestion">确 定</el-button>
                      </span>
                  </el-dialog>
                </div>
              </div>
              <div class="paperBox">
                <div class="paperItem" :id="'paperItem'+indexOuter" v-for="(itemOuter,indexOuter) in paperInfo.paperPartitionList" :key="indexOuter">
                  <div class="paperHeader">
                    <span>{{ changeNumToHan(indexOuter + 1) }}、
                      <span v-if="itemOuter.showEditTitle == false">
                        <span style="margin-right: 8px;">{{ itemOuter.partitionName }}</span>
                        <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideTitleEdit(indexOuter,false)" :disabled="stu != 'edit'"></el-button>
                      </span>
                      <span v-else>
                        <span style="margin-right: 8px;"><el-input size="small" v-model="itemOuter.partitionName" placeholder="请输入大题名称" maxlength="30" show-word-limit></el-input></span>
                        <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideTitleEdit(indexOuter,true,itemOuter.partitionId)" :disabled="stu != 'edit'"></el-button>
                      </span>
                      <span v-if="itemOuter.questionNum">（第1题～第{{ itemOuter.questionNum }}题，合计{{ itemOuter.questionNum }}道题，满分{{ itemOuter.questionScore }}分）</span>
                    </span>
                    <div>

                      <el-button type="success" size="mini" @click="importQuestionInfo(itemOuter.partitionId)" v-if="isGroupPapers">批量导入</el-button>
                      <el-button type="primary" size="mini" @click="selectQuestion(itemOuter.partitionId,paperInfo.bankId,paperId)" :disabled="stu != 'edit'">从题库选题</el-button>
                      <el-button type="danger" size="mini" @click="deleteBigQuestion(itemOuter.partitionId)" v-if="isGroupPapers">删除大题</el-button>
                    </div>
                  </div>
                  <div class="questionBox">
                    <div v-for="(item,index) in itemOuter.paperList" :key="index">
                      <!--单选题-->
                      <div class="questionItem" v-if="item.questionType == '10'">
                        <div class="questionInner">
                          <div class="questionInnerLeft">
                            <div class="questionTitle">
                              <span class="questionType">单选题</span><span>{{ index+1 }}.</span><span v-html="item.questionName" class="hh"></span>
                            </div>
                            <div class="questionOption">
                              <span v-for="(itemInner,indexInner) in JSON.parse(item.optionJson)" :key="indexInner">
                                {{ itemInner.key }}.{{ itemInner.content }}
                                <p v-if="itemInner.img">
                                  <el-image
                                      style="height: 100px;min-height: 100px;"
                                      :src="itemInner.img"
                                      :fit="cover"
                                      :preview-src-list="[itemInner.img]">
                                  </el-image>
                                </p>

                              </span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                正确答案：{{ item.standardAnswer }}
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="item.answerAnalysis"></span>
                              </span>
                            </div>
                          </div>
                          <div class="questionInnerRight">
                            <div>
                              <el-button type="warning" size="mini" icon="el-icon-edit" @click="handleCreate(itemOuter.partitionId,item.paperQuestionId)" :disabled="stu != 'edit'">编辑</el-button>
                              <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDel(itemOuter.paperList,item.paperQuestionId)" :disabled="stu != 'edit'">删除</el-button>
                            </div>
                            <div class="mt16">
                              <span v-if="item.showEditScore == false">
                                <span style="margin-right: 8px;">分数：{{ item.score }}分</span>
                                  <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEdit(indexOuter,index,false)" :disabled="stu != 'edit'"></el-button>
                                </span>
                              <span v-else>
                                  <span style="margin-right: 8px;width: 88px">
                                    <el-input size="small" v-model="item.score" placeholder="请输入大题名称" @keyup.native="item.score = zF.oninput2(item.score, 2)"></el-input>
                                  </span>
                                  <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEdit(indexOuter,index,true,item.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--多选题-->
                      <div class="questionItem" v-if="item.questionType == '20'">
                        <div class="questionInner">
                          <div class="questionInnerLeft">
                            <div class="questionTitle">
                              <span class="questionType">多选题</span><span>{{ index+1 }}.</span><span v-html="item.questionName" class="hh"></span>
                            </div>
                            <div class="questionOption">
                              <span v-for="(itemInner,indexInner) in JSON.parse(item.optionJson)" :key="indexInner">
                                {{ itemInner.key }}.{{ itemInner.content }}
                                <p v-if="itemInner.img">
                                  <el-image
                                      style="height: 100px;min-height: 100px;"
                                      :src="itemInner.img"
                                      :fit="cover"
                                      :preview-src-list="[itemInner.img]">
                                  </el-image>
                                </p>

                              </span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                正确答案：{{ item.standardAnswer }}
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="item.answerAnalysis"></span>
                              </span>

                            </div>
                          </div>
                          <div class="questionInnerRight">
                            <div>
                              <el-button type="warning" size="mini" icon="el-icon-edit" @click="handleCreate(itemOuter.partitionId,item.paperQuestionId)" :disabled="stu != 'edit'">编辑</el-button>
                              <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDel(itemOuter.paperList,item.paperQuestionId)" :disabled="stu != 'edit'">删除</el-button>
                            </div>
                            <div class="mt16">
                              <span v-if="item.showEditScore == false">
                                <span style="margin-right: 8px;">分数：{{ item.score }}分</span>
                                  <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEdit(indexOuter,index,false)" :disabled="stu != 'edit'"></el-button>
                                </span>
                              <span v-else>
                                  <span style="margin-right: 8px;width: 88px">
                                    <el-input size="small" v-model="item.score" placeholder="请输入大题名称" @keyup.native="item.score = zF.oninput2(item.score, 2)"></el-input>
                                  </span>
                                  <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEdit(indexOuter,index,true,item.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--填空题-->
                      <div class="questionItem" v-if="item.questionType == '40'">
                        <div class="questionInner">
                          <div class="questionInnerLeft">
                            <div class="questionTitle">
                              <span class="questionType">填空题</span><span>{{ index+1 }}.</span><span v-html="item.questionName" class="hh"></span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                参考答案：
                                <p class="questionAnswerS" v-for="(itemInner,indexInner) in item.standardAnswer.split('####')" :key="indexInner">{{itemInner}}</p>
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="item.answerAnalysis"></span>
                              </span>

                            </div>
                          </div>
                          <div class="questionInnerRight">
                            <div>
                              <el-button type="warning" size="mini" icon="el-icon-edit" @click="handleCreate(itemOuter.partitionId,item.paperQuestionId)" :disabled="stu != 'edit'">编辑</el-button>
                              <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDel(itemOuter.paperList,item.paperQuestionId)" :disabled="stu != 'edit'">删除</el-button>
                            </div>
                            <div class="mt16">
                              <span v-if="item.showEditScore == false">
                                <span style="margin-right: 8px;">分数：{{ item.score }}分</span>
                                  <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEdit(indexOuter,index,false)" :disabled="stu != 'edit'"></el-button>
                                </span>
                              <span v-else>
                                  <span style="margin-right: 8px;width: 88px">
                                    <el-input size="small" v-model="item.score" placeholder="请输入大题名称" @keyup.native="item.score = zF.oninput2(item.score, 2)"></el-input>
                                  </span>
                                  <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEdit(indexOuter,index,true,item.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--判断题-->
                      <div class="questionItem" v-if="item.questionType == '30'">
                        <div class="questionInner">
                          <div class="questionInnerLeft">
                            <div class="questionTitle">
                              <span class="questionType">判断题</span><span>{{ index+1 }}.</span><span v-html="item.questionName" class="hh"></span>
                            </div>
                            <div class="questionOption">
                              <span v-for="(itemInner,indexInner) in JSON.parse(item.optionJson)" :key="indexInner">
                                {{ itemInner.key }}.{{ itemInner.content }}
                                <p v-if="itemInner.img">
                                  <el-image
                                      style="height: 100px;min-height: 100px;"
                                      :src="itemInner.img"
                                      :fit="cover"
                                      :preview-src-list="[itemInner.img]">
                                  </el-image>
                                </p>

                              </span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                正确答案：{{ item.standardAnswer }}
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                 解析：<span v-html="item.answerAnalysis"></span>
                              </span>

                            </div>
                          </div>
                          <div class="questionInnerRight">
                            <div>
                              <el-button type="warning" size="mini" icon="el-icon-edit" @click="handleCreate(itemOuter.partitionId,item.paperQuestionId)" :disabled="stu != 'edit'">编辑</el-button>
                              <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDel(itemOuter.paperList,item.paperQuestionId)" :disabled="stu != 'edit'">删除</el-button>
                            </div>
                            <div class="mt16">
                              <span v-if="item.showEditScore == false">
                                <span style="margin-right: 8px;">分数：{{ item.score }}分</span>
                                  <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEdit(indexOuter,index,false)" :disabled="stu != 'edit'"></el-button>
                                </span>
                              <span v-else>
                                  <span style="margin-right: 8px;width: 88px">
                                    <el-input size="small" v-model="item.score" placeholder="请输入大题名称" @keyup.native="item.score = zF.oninput2(item.score, 2)"></el-input>
                                  </span>
                                  <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEdit(indexOuter,index,true,item.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--简答题-->
                      <div class="questionItem" v-if="item.questionType == '50'">
                        <div class="questionInner">
                          <div class="questionInnerLeft">
                            <div class="questionTitle">
                              <span class="questionType">简答题</span><span>{{ index+1 }}.</span><span v-html="item.questionName" class="hh"></span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                参考答案：{{ item.standardAnswer }}
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="item.answerAnalysis"></span>
                              </span>

                            </div>
                          </div>
                          <div class="questionInnerRight">
                            <div>
                              <el-button type="warning" size="mini" icon="el-icon-edit" @click="handleCreate(itemOuter.partitionId,item.paperQuestionId)" :disabled="stu != 'edit'">编辑</el-button>
                              <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDel(itemOuter.paperList,item.paperQuestionId)" :disabled="stu != 'edit'">删除</el-button>
                            </div>
                            <div class="mt16">
                              <span v-if="item.showEditScore == false">
                                <span style="margin-right: 8px;">分数：{{ item.score }}分</span>
                                  <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEdit(indexOuter,index,false)" :disabled="stu != 'edit'"></el-button>
                                </span>
                              <span v-else>
                                  <span style="margin-right: 8px;width: 88px">
                                    <el-input size="small" v-model="item.score" placeholder="请输入大题名称" @keyup.native="item.score = zF.oninput2(item.score, 2)"></el-input>
                                  </span>
                                  <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEdit(indexOuter,index,true,item.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--计算题-->
                      <div class="questionItem" v-if="item.questionType == '60'">
                        <div class="questionInner">
                          <div class="questionInnerLeft">
                            <div class="questionTitle">
                              <span class="questionType">计算题</span><span>{{ index+1 }}.</span><span v-html="item.questionName" class="hh"></span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                参考答案：{{ item.standardAnswer }}
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="item.answerAnalysis"></span>
                              </span>

                            </div>
                          </div>
                          <div class="questionInnerRight">
                            <div>
                              <el-button type="warning" size="mini" icon="el-icon-edit" @click="handleCreate(itemOuter.partitionId,item.paperQuestionId)" :disabled="stu != 'edit'">编辑</el-button>
                              <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDel(itemOuter.paperList,item.paperQuestionId)" :disabled="stu != 'edit'">删除</el-button>
                            </div>
                            <div class="mt16">
                              <span v-if="item.showEditScore == false">
                                <span style="margin-right: 8px;">分数：{{ item.score }}分</span>
                                  <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEdit(indexOuter,index,false)" :disabled="stu != 'edit'"></el-button>
                                </span>
                                <span v-else>
                                  <span style="margin-right: 8px;width: 88px">
                                    <el-input size="small" v-model="item.score" placeholder="请输入大题名称" @keyup.native="item.score = zF.oninput2(item.score, 2)"></el-input>
                                  </span>
                                  <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEdit(indexOuter,index,true,item.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--论述题-->
                      <div class="questionItem" v-if="item.questionType == '64'">
                        <div class="questionInner">
                          <div class="questionInnerLeft">
                            <div class="questionTitle">
                              <span class="questionType">论述题</span><span>{{ index+1 }}.</span><span v-html="item.questionName" class="hh"></span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                参考答案：{{ item.standardAnswer }}
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="item.answerAnalysis"></span>
                              </span>
                            </div>
                          </div>
                          <div class="questionInnerRight">
                            <div>
                              <el-button type="warning" size="mini" icon="el-icon-edit" @click="handleCreate(itemOuter.partitionId,item.paperQuestionId)" :disabled="stu != 'edit'">编辑</el-button>
                              <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDel(itemOuter.paperList,item.paperQuestionId)" :disabled="stu != 'edit'">删除</el-button>
                            </div>
                            <div class="mt16">
                              <span v-if="item.showEditScore == false">
                                <span style="margin-right: 8px;">分数：{{ item.score }}分</span>
                                  <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEdit(indexOuter,index,false)" :disabled="stu != 'edit'"></el-button>
                                </span>
                              <span v-else>
                                  <span style="margin-right: 8px;width: 88px">
                                    <el-input size="small" v-model="item.score" placeholder="请输入大题名称" @keyup.native="item.score = zF.oninput2(item.score, 2)"></el-input>
                                  </span>
                                  <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEdit(indexOuter,index,true,item.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--案例分析题-->
                      <div class="questionItem" v-if="item.questionType == '68'">
                        <div class="questionInner">
                          <div class="questionInnerLeft">
                            <div class="questionTitle">
                              <span class="questionType">案例分析题</span><span>{{ index+1 }}.</span><span v-html="item.questionName" class="hh"></span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                参考答案：{{ item.standardAnswer }}
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="item.answerAnalysis"></span>
                              </span>
                            </div>
                          </div>
                          <div class="questionInnerRight">
                            <div>
                              <el-button type="warning" size="mini" icon="el-icon-edit" @click="handleCreate(itemOuter.partitionId,item.paperQuestionId)" :disabled="stu != 'edit'">编辑</el-button>
                              <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDel(itemOuter.paperList,item.paperQuestionId)" :disabled="stu != 'edit'">删除</el-button>
                            </div>
                            <div class="mt16">
                              <span v-if="item.showEditScore == false">
                                <span style="margin-right: 8px;">分数：{{ item.score }}分</span>
                                  <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEdit(indexOuter,index,false)" :disabled="stu != 'edit'"></el-button>
                                </span>
                              <span v-else>
                                  <span style="margin-right: 8px;width: 88px">
                                    <el-input size="small" v-model="item.score" placeholder="请输入大题名称" @keyup.native="item.score = zF.oninput2(item.score, 2)"></el-input>
                                  </span>
                                  <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEdit(indexOuter,index,true,item.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--组合题(单一题型)-->
                      <div class="questionItem" v-if="item.questionType == '80'">
                        <div class="questionInner">
                          <div class="questionInnerLeft">
                            <div class="questionTitle">
                              <span class="questionType">组合题(单一题型)</span><span>{{ index+1 }}.</span><span v-html="item.questionName" class="hh"></span>
                            </div>
                            <span class="showChildrenQuestion" @click="showChildrenQuestion(indexOuter,index)">点击{{item.showChild?'隐藏':'查看'}}子题目</span>
                            <!--子题目-->
                            <div class="childrenQuestion" v-if="item.showChild">

                              <div v-for="(itemInner,indexInner) in item.childList" :key="indexInner">
                                <!--子题目~单选-->
                                <div class="childrenQuestionItem" v-if="itemInner.questionType == '10'">
                                  <div class="questionTitle">
                                    <span class="questionType">单选题</span><span>{{ indexInner + 1 }}.</span><span v-html="itemInner.questionName" class="hh"></span>
                                  </div>
                                  <div class="questionOption">
                                    <span v-for="(itemInner2,indexInner2) in JSON.parse(itemInner.optionJson)" :key="indexInner2">
                                      {{ itemInner2.key }}.{{ itemInner2.content }}
                                      <p v-if="itemInner2.img">
                                        <el-image
                                            style="height: 100px;min-height: 100px;"
                                            :src="itemInner2.img"
                                            :fit="cover"
                                            :preview-src-list="[itemInner2.img]">
                                        </el-image>
                                      </p>

                                    </span>
                                  </div>
                                  <div class="questionAnswer">
                                    <span>
                                      正确答案：{{ itemInner.standardAnswer }}
                                    </span>
                                  </div>
                                  <div class="questionAnalysis">
                                    <span>
                                      解析：<span v-html="itemInner.answerAnalysis"></span>
                                    </span>
                                  </div>
                                  <div class="mt16">
                                    <span v-if="itemInner.showEditScore == false">
                                      <span style="margin-right: 8px;">分数：{{ itemInner.score }}分</span>
                                        <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,false)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                      <span v-else>
                                        <span style="margin-right: 8px;width: 88px">
                                          <el-input size="small" v-model="itemInner.score" placeholder="请输入大题名称" @keyup.native="itemInner.score = zF.oninput2(itemInner.score, 2)"></el-input>
                                        </span>
                                        <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,true,itemInner.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                    </div>
                                </div>
                                <!--子题目~多选-->
                                <div class="childrenQuestionItem" v-if="itemInner.questionType == '20'">
                                  <div class="questionTitle">
                                    <span class="questionType">多选题</span><span>{{ indexInner + 1 }}.</span><span v-html="itemInner.questionName" class="hh"></span>
                                  </div>
                                  <div class="questionOption">
                                    <span v-for="(itemInner2,indexInner2) in JSON.parse(itemInner.optionJson)" :key="indexInner2">
                                      {{ itemInner2.key }}.{{ itemInner2.content }}
                                      <p v-if="itemInner2.img">
                                        <el-image
                                            style="height: 100px;min-height: 100px;"
                                            :src="itemInner2.img"
                                            :fit="cover"
                                            :preview-src-list="[itemInner2.img]">
                                        </el-image>
                                      </p>

                                    </span>
                                  </div>
                                  <div class="questionAnswer">
                                    <span>
                                      正确答案：{{ itemInner.standardAnswer }}
                                    </span>
                                  </div>
                                  <div class="questionAnalysis">
                                    <span>
                                      解析：<span v-html="itemInner.answerAnalysis"></span>
                                    </span>
                                  </div>
                                  <div class="mt16">
                                    <span v-if="itemInner.showEditScore == false">
                                      <span style="margin-right: 8px;">分数：{{ itemInner.score }}分</span>
                                        <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,false)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                      <span v-else>
                                        <span style="margin-right: 8px;width: 88px">
                                          <el-input size="small" v-model="itemInner.score" placeholder="请输入大题名称" @keyup.native="itemInner.score = zF.oninput2(itemInner.score, 2)"></el-input>
                                        </span>
                                        <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,true,itemInner.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                  </div>
                                </div>
                                <!--子题目~填空-->
                                <div class="childrenQuestionItem" v-if="itemInner.questionType == '40'">
                                  <div class="questionTitle">
                                    <span class="questionType">填空题</span><span>{{ indexInner + 1 }}.</span><span v-html="itemInner.questionName" class="hh"></span>
                                  </div>
                                  <div class="questionAnswer">
                                    <span>
                                      参考答案：
                                      <p class="questionAnswerS" v-for="(itemInner2,indexInner2) in itemInner.standardAnswer.split('####')" :key="indexInner2">{{itemInner2}}</p>
                                    </span>
                                  </div>
                                  <div class="questionAnalysis">
                                    <span>
                                      解析：<span v-html="itemInner.answerAnalysis"></span>
                                    </span>
                                  </div>
                                  <div class="mt16">
                                    <span v-if="itemInner.showEditScore == false">
                                      <span style="margin-right: 8px;">分数：{{ itemInner.score }}分</span>
                                        <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,false)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                      <span v-else>
                                        <span style="margin-right: 8px;width: 88px">
                                          <el-input size="small" v-model="itemInner.score" placeholder="请输入大题名称" @keyup.native="itemInner.score = zF.oninput2(itemInner.score, 2)"></el-input>
                                        </span>
                                        <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,true,itemInner.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                  </div>
                                </div>
                                <!--子题目~判断-->
                                <div class="childrenQuestionItem" v-if="itemInner.questionType == '30'">
                                  <div class="questionTitle">
                                    <span class="questionType">判断题</span><span>{{ indexInner + 1 }}.</span><span v-html="itemInner.questionName" class="hh"></span>
                                  </div>
                                  <div class="questionOption">
                                    <span v-for="(itemInner2,indexInner2) in JSON.parse(itemInner.optionJson)" :key="indexInner2">
                                      {{ itemInner2.key }}.{{ itemInner2.content }}
                                      <p v-if="itemInner2.img">
                                        <el-image
                                            style="height: 100px;min-height: 100px;"
                                            :src="itemInner2.img"
                                            :fit="cover"
                                            :preview-src-list="[itemInner2.img]">
                                        </el-image>
                                      </p>

                                    </span>
                                  </div>
                                  <div class="questionAnswer">
                                    <span>
                                      正确答案：{{ itemInner.standardAnswer }}
                                    </span>
                                  </div>
                                  <div class="questionAnalysis">
                                    <span>
                                       解析：<span v-html="itemInner.answerAnalysis"></span>
                                    </span>
                                  </div>
                                  <div class="mt16">
                                    <span v-if="itemInner.showEditScore == false">
                                      <span style="margin-right: 8px;">分数：{{ itemInner.score }}分</span>
                                        <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,false)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                      <span v-else>
                                        <span style="margin-right: 8px;width: 88px">
                                          <el-input size="small" v-model="itemInner.score" placeholder="请输入大题名称" @keyup.native="itemInner.score = zF.oninput2(itemInner.score, 2)"></el-input>
                                        </span>
                                        <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,true,itemInner.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                  </div>
                                </div>
                                <!--子题目~简答-->
                                <div class="childrenQuestionItem" v-if="itemInner.questionType == '50'">
                                  <div class="questionTitle">
                                    <span class="questionType">简答题</span><span>{{ indexInner + 1 }}.</span><span v-html="itemInner.questionName" class="hh"></span>
                                  </div>
                                  <div class="questionAnswer">
                                    <span>
                                      参考答案：{{ itemInner.standardAnswer }}
                                    </span>
                                  </div>
                                  <div class="questionAnalysis">
                                    <span>
                                      解析：<span v-html="itemInner.answerAnalysis"></span>
                                    </span>

                                  </div>
                                  <div class="mt16">
                                    <span v-if="itemInner.showEditScore == false">
                                      <span style="margin-right: 8px;">分数：{{ itemInner.score }}分</span>
                                        <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,false)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                      <span v-else>
                                        <span style="margin-right: 8px;width: 88px">
                                          <el-input size="small" v-model="itemInner.score" placeholder="请输入大题名称" @keyup.native="itemInner.score = zF.oninput2(itemInner.score, 2)"></el-input>
                                        </span>
                                        <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,true,itemInner.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                  </div>
                                </div>
                                <!--子题目~计算-->
                                <div class="childrenQuestionItem" v-if="itemInner.questionType == '60'">
                                  <div class="questionTitle">
                                    <span class="questionType">计算题</span><span>{{ indexInner + 1 }}.</span><span v-html="itemInner.questionName" class="hh"></span>
                                  </div>
                                  <div class="questionAnswer">
                                    <span>
                                      参考答案：{{ itemInner.standardAnswer }}
                                    </span>
                                  </div>
                                  <div class="questionAnalysis">
                                    <span>
                                      解析：<span v-html="itemInner.answerAnalysis"></span>
                                    </span>

                                  </div>
                                  <div class="mt16">
                                    <span v-if="itemInner.showEditScore == false">
                                      <span style="margin-right: 8px;">分数：{{ itemInner.score }}分</span>
                                        <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,false)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                      <span v-else>
                                        <span style="margin-right: 8px;width: 88px">
                                          <el-input size="small" v-model="itemInner.score" placeholder="请输入大题名称" @keyup.native="itemInner.score = zF.oninput2(itemInner.score, 2)"></el-input>
                                        </span>
                                        <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,true,itemInner.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="questionInnerRight">
                            <div>
                              <el-button type="warning" size="mini" icon="el-icon-edit" @click="handleCreate(itemOuter.partitionId,item.paperQuestionId)" :disabled="stu != 'edit'">编辑</el-button>
                              <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDel(itemOuter.paperList,item.paperQuestionId)" :disabled="stu != 'edit'">删除</el-button>
                            </div>

                          </div>
                        </div>
                      </div>
                      <!--组合题(复合题型)-->
                      <div class="questionItem" v-if="item.questionType == '70'">
                        <div class="questionInner">
                          <div class="questionInnerLeft">
                            <div class="questionTitle">
                              <span class="questionType">组合题(复合题型)</span><span>{{ index+1 }}.</span><span v-html="item.questionName" class="hh"></span>
                            </div>
                            <span class="showChildrenQuestion" @click="showChildrenQuestion(indexOuter,index)">点击{{item.showChild?'隐藏':'查看'}}子题目</span>
                            <!--子题目-->
                            <div class="childrenQuestion" v-if="item.showChild">
                              <div v-for="(itemInner,indexInner) in item.childList" :key="indexInner">
                                <!--子题目~单选-->
                                <div class="childrenQuestionItem" v-if="itemInner.questionType == '10'">
                                  <div class="questionTitle">
                                    <span class="questionType">单选题</span><span>{{ indexInner + 1 }}.</span><span v-html="itemInner.questionName" class="hh"></span>
                                  </div>
                                  <div class="questionOption">
                                    <span v-for="(itemInner2,indexInner2) in JSON.parse(itemInner.optionJson)" :key="indexInner2">
                                      {{ itemInner2.key }}.{{ itemInner2.content }}
                                      <p v-if="itemInner2.img">
                                        <el-image
                                            style="height: 100px;min-height: 100px;"
                                            :src="itemInner2.img"
                                            :fit="cover"
                                            :preview-src-list="[itemInner2.img]">
                                        </el-image>
                                      </p>

                                    </span>
                                  </div>
                                  <div class="questionAnswer">
                                    <span>
                                      正确答案：{{ itemInner.standardAnswer }}
                                    </span>
                                  </div>
                                  <div class="questionAnalysis">
                                    <span>
                                      解析：<span v-html="itemInner.answerAnalysis"></span>
                                    </span>
                                  </div>
                                  <div class="mt16">
                                    <span v-if="itemInner.showEditScore == false">
                                      <span style="margin-right: 8px;">分数：{{ itemInner.score }}分</span>
                                        <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,false)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                    <span v-else>
                                        <span style="margin-right: 8px;width: 88px">
                                          <el-input size="small" v-model="itemInner.score" placeholder="请输入大题名称" @keyup.native="itemInner.score = zF.oninput2(itemInner.score, 2)"></el-input>
                                        </span>
                                        <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,true,itemInner.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                  </div>
                                </div>
                                <!--子题目~多选-->
                                <div class="childrenQuestionItem" v-if="itemInner.questionType == '20'">
                                  <div class="questionTitle">
                                    <span class="questionType">多选题</span><span>{{ indexInner + 1 }}.</span><span v-html="itemInner.questionName" class="hh"></span>
                                  </div>
                                  <div class="questionOption">
                                    <span v-for="(itemInner2,indexInner2) in JSON.parse(itemInner.optionJson)" :key="indexInner2">
                                      {{ itemInner2.key }}.{{ itemInner2.content }}
                                      <p v-if="itemInner2.img">
                                        <el-image
                                            style="height: 100px;min-height: 100px;"
                                            :src="itemInner2.img"
                                            :fit="cover"
                                            :preview-src-list="[itemInner2.img]">
                                        </el-image>
                                      </p>

                                    </span>
                                  </div>
                                  <div class="questionAnswer">
                                    <span>
                                      正确答案：{{ itemInner.standardAnswer }}
                                    </span>
                                  </div>
                                  <div class="questionAnalysis">
                                    <span>
                                      解析：<span v-html="itemInner.answerAnalysis"></span>
                                    </span>
                                  </div>
                                  <div class="mt16">
                                    <span v-if="itemInner.showEditScore == false">
                                      <span style="margin-right: 8px;">分数：{{ itemInner.score }}分</span>
                                        <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,false)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                    <span v-else>
                                        <span style="margin-right: 8px;width: 88px">
                                          <el-input size="small" v-model="itemInner.score" placeholder="请输入大题名称" @keyup.native="itemInner.score = zF.oninput2(itemInner.score, 2)"></el-input>
                                        </span>
                                        <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,true,itemInner.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                  </div>
                                </div>
                                <!--子题目~填空-->
                                <div class="childrenQuestionItem" v-if="itemInner.questionType == '40'">
                                  <div class="questionTitle">
                                    <span class="questionType">填空题</span><span>{{ indexInner + 1 }}.</span><span v-html="itemInner.questionName" class="hh"></span>
                                  </div>
                                  <div class="questionAnswer">
                                    <span>
                                      参考答案：
                                      <p class="questionAnswerS" v-for="(itemInner2,indexInner2) in itemInner.standardAnswer.split('####')" :key="indexInner2">{{itemInner2}}</p>
                                    </span>
                                  </div>
                                  <div class="questionAnalysis">
                                    <span>
                                      解析：<span v-html="itemInner.answerAnalysis"></span>
                                    </span>
                                  </div>
                                  <div class="mt16">
                                    <span v-if="itemInner.showEditScore == false">
                                      <span style="margin-right: 8px;">分数：{{ itemInner.score }}分</span>
                                        <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,false)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                    <span v-else>
                                        <span style="margin-right: 8px;width: 88px">
                                          <el-input size="small" v-model="itemInner.score" placeholder="请输入大题名称" @keyup.native="itemInner.score = zF.oninput2(itemInner.score, 2)"></el-input>
                                        </span>
                                        <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,true,itemInner.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                  </div>
                                </div>
                                <!--子题目~判断-->
                                <div class="childrenQuestionItem" v-if="itemInner.questionType == '30'">
                                  <div class="questionTitle">
                                    <span class="questionType">判断题</span><span>{{ indexInner + 1 }}.</span><span v-html="itemInner.questionName" class="hh"></span>
                                  </div>
                                  <div class="questionOption">
                                    <span v-for="(itemInner2,indexInner2) in JSON.parse(itemInner.optionJson)" :key="indexInner2">
                                      {{ itemInner2.key }}.{{ itemInner2.content }}
                                      <p v-if="itemInner2.img">
                                        <el-image
                                            style="height: 100px;min-height: 100px;"
                                            :src="itemInner2.img"
                                            :fit="cover"
                                            :preview-src-list="[itemInner2.img]">
                                        </el-image>
                                      </p>

                                    </span>
                                  </div>
                                  <div class="questionAnswer">
                                    <span>
                                      正确答案：{{ itemInner.standardAnswer }}
                                    </span>
                                  </div>
                                  <div class="questionAnalysis">
                                    <span>
                                       解析：<span v-html="itemInner.answerAnalysis"></span>
                                    </span>
                                  </div>
                                  <div class="mt16">
                                    <span v-if="itemInner.showEditScore == false">
                                      <span style="margin-right: 8px;">分数：{{ itemInner.score }}分</span>
                                        <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,false)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                    <span v-else>
                                        <span style="margin-right: 8px;width: 88px">
                                          <el-input size="small" v-model="itemInner.score" placeholder="请输入大题名称" @keyup.native="itemInner.score = zF.oninput2(itemInner.score, 2)"></el-input>
                                        </span>
                                        <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,true,itemInner.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                  </div>
                                </div>
                                <!--子题目~简答-->
                                <div class="childrenQuestionItem" v-if="itemInner.questionType == '50'">
                                  <div class="questionTitle">
                                    <span class="questionType">简答题</span><span>{{ indexInner + 1 }}.</span><span v-html="itemInner.questionName" class="hh"></span>
                                  </div>
                                  <div class="questionAnswer">
                                    <span>
                                      参考答案：{{ itemInner.standardAnswer }}
                                    </span>
                                  </div>
                                  <div class="questionAnalysis">
                                    <span>
                                      解析：<span v-html="itemInner.answerAnalysis"></span>
                                    </span>

                                  </div>
                                  <div class="mt16">
                                    <span v-if="itemInner.showEditScore == false">
                                      <span style="margin-right: 8px;">分数：{{ itemInner.score }}分</span>
                                        <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,false)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                    <span v-else>
                                        <span style="margin-right: 8px;width: 88px">
                                          <el-input size="small" v-model="itemInner.score" placeholder="请输入大题名称" @keyup.native="itemInner.score = zF.oninput2(itemInner.score, 2)"></el-input>
                                        </span>
                                        <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,true,itemInner.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                  </div>
                                </div>
                                <!--子题目~计算-->
                                <div class="childrenQuestionItem" v-if="itemInner.questionType == '60'">
                                  <div class="questionTitle">
                                    <span class="questionType">计算题</span><span>{{ indexInner + 1 }}.</span><span v-html="itemInner.questionName" class="hh"></span>
                                  </div>
                                  <div class="questionAnswer">
                                    <span>
                                      参考答案：{{ itemInner.standardAnswer }}
                                    </span>
                                  </div>
                                  <div class="questionAnalysis">
                                    <span>
                                      解析：<span v-html="itemInner.answerAnalysis"></span>
                                    </span>

                                  </div>
                                  <div class="mt16">
                                    <span v-if="itemInner.showEditScore == false">
                                      <span style="margin-right: 8px;">分数：{{ itemInner.score }}分</span>
                                        <el-button type="warning" size="mini" icon="el-icon-edit" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,false)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                    <span v-else>
                                        <span style="margin-right: 8px;width: 88px">
                                          <el-input size="small" v-model="itemInner.score" placeholder="请输入大题名称" @keyup.native="itemInner.score = zF.oninput2(itemInner.score, 2)"></el-input>
                                        </span>
                                        <el-button type="success" size="mini" icon="el-icon-check" circle @click="showHideScoreEditInner(indexOuter,index,indexInner,true,itemInner.paperQuestionId)" :disabled="stu != 'edit'"></el-button>
                                      </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="questionInnerRight">
                            <div>
                              <el-button type="warning" size="mini" icon="el-icon-edit" @click="handleCreate(itemOuter.partitionId,item.paperQuestionId)" :disabled="stu != 'edit'">编辑</el-button>
                              <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDel(itemOuter.paperList,item.paperQuestionId)" :disabled="stu != 'edit'">删除</el-button>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <courseQuestionBank
          ref="selectQuestion"
      ></courseQuestionBank>
       <!--鉴定机构通用导入-->
      <!-- businessType  导入业务类型标识 [ BUSINESS_TYPE ]-->
      <!-- importType  导入类型 学员-->
      <!-- unit  单位 学员-人-->
      <!--fileType  EXCEL  ZIP-->
      <accreditationAgencyImport
        ref="accreditationAgencyImport"
        @eventBus="accreditationAgencyImportBack"
        businessType="EXAM_PAPER_PARTITION_IMPORT"
        importType="题目"
        unit="道"
        fileType="EXCEL"
        filePath="/static/试题导入模板.xls"
        desc="1.根据导入的题目，优先从题库根据题干进行筛选，如在题库中没有找到相应的题目，则根据填写的内容新增题目;
2.请确保导入模板中的题目内容与题库中的题目内容一致；
3.请根据模板提示填写导入模板。"
      />
    </div>
  </div>
</template>
<script>
import accreditationAgencyImport from "@/components/accreditationAgencyImport.vue";
import courseQuestionBank from "@/views/inkQuestionBank/examinationPaperManage/popup/selectQuestion";
export default {
  name: "inkQuestionBank_examinationPaperManage_tacticsUpdate",
  components: {
    accreditationAgencyImport,
    courseQuestionBank,
  },
  data() {
    return {
      paperId: '',
      stu: '',
        isGroupPapers: '',

      questionName:'',
      questionType:'',

      retrievalData: {},
      QuestionTypeList: [],


      paperInfo: {
        bankId:'',
        paperName:'',
        paperCode:'',
        paperPartitionList: [],
        partitionList: [],
      },
      //自行组卷-新增大题
      dialogVisibleBigQuestion: false,
        bigQuestionTitle:'',

      // 试卷名称修改展示隐藏
      showEditPaperName: false,
      // 试卷编码修改展示隐藏
      showEditPaperCode: false
    };
  },
  mixins: [],
  created() {
    this.paperId = this.$route.query.paperId
    this.stu = this.$route.query.stu
      console.log('isGroupPapers',this.$route.query.isGroupPapers)
      this.isGroupPapers = this.$route.query.isGroupPapers||''
    if(sessionStorage.getItem('questionNameStr')){
      this.questionName = sessionStorage.getItem('questionNameStr')
      sessionStorage.removeItem('questionNameStr')
    }
    if(sessionStorage.getItem('questionTypeStr')){
      this.questionType = sessionStorage.getItem('questionTypeStr')
      sessionStorage.removeItem('questionTypeStr')
    }

    this.getQuestionTypeList();

    this.getPaperInfo();



  },
  computed: {

  },
  methods: {
    importQuestionInfo(businessId){
      this.$refs.accreditationAgencyImport.showPopup(businessId)
    },
    accreditationAgencyImportBack(){
      this.getPaperInfo()
    },
    // 去试卷管理
    goTactics(){
        this.$router.push({
            path: "/inkQuestionBank/examinationPaperManage/tactics",
        });
    },
    // 从题库选题
    selectQuestion(partitionId,bankId,paperId){
      this.$refs.selectQuestion.showPopUp(partitionId,bankId,paperId);
    },
    // 展示隐藏子题目
    showChildrenQuestion(indexOuter,index){
      // console.log(indexOuter,index)
      this.paperInfo.paperPartitionList[indexOuter].paperList.forEach((e,i)=>{
        if(i == index){
          if(!e.showChild){
            this.$set(this.paperInfo.paperPartitionList[indexOuter].paperList[i],'showChild',true)
          }else{
            this.$set(this.paperInfo.paperPartitionList[indexOuter].paperList[i],'showChild',false)
          }
        }
        // else{
        //   this.$set(this.paperInfo.paperPartitionList[indexOuter].paperList[i],'showChild',false)
        // }
      })
    },
      // 删除大题
      deleteBigQuestion(partitionId){
          this.$confirm("确认删除该大题吗?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
          }).then(() => {
              this.$post("/biz/exam/bank/paper/partition/delete", {
                  partitionId
              },3000,true,6).then(() => {
                  this.$message({
                      type: "success",
                      message: "删除成功!",
                  });
                  this.getPaperInfo();
              });
          });
      },
    // 新增编辑
    handleCreate(partitionId,paperQuestionId) {
      // stu 1题库  2组合题(单一题型)/组合题(复合题型)  3试卷
      let stu = '3'
      sessionStorage.setItem('questionNameStr',this.questionName)
      sessionStorage.setItem('questionTypeStr',this.questionType)
      this.$router.push({
        path: '/inkQuestionBank/examinationPaperManage/QuestionAddOrEdit',
        query: {
          stu: stu,
          paperId: this.paperId,
          partitionId: partitionId,
          paperQuestionId: paperQuestionId,

        }
      })
    },
    // 删除题目
    handleDel(paperList,paperQuestionId) {
      // console.log(paperQuestionId)
      if(paperList.length == 1){
        this.$message.error('每大题至少保留一道题目！')
        return
      }
      this.$confirm("确认删除该题目吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$post("/biz/exam/bank/paper/question/delete", {
          paperQuestionId
        },3000,true,6).then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getPaperInfo();
        });
      });
    },
    // 左侧点击跳到锚点指定位置
    currentActive(index){
      let id = 'paperItem'+index
      const currentDom = document.getElementById(id)
      if(currentDom){
        currentDom.scrollIntoView({
          behavior: 'smooth' // 让滚动更丝滑
        }) // 你也可以设置丝滑滚动
      }
    },
    //获取测评分类标识--码值
    getQuestionTypeList() {
      const QuestionType = this.$setDictionary("TOOL_QUESTION_TYPE", "list");
      for (const key in QuestionType) {
        this.QuestionTypeList.push({
          value: key,
          label: QuestionType[key],
        });
      }
    },
    // 展示隐藏大题编辑
    showHidePaperNameEdit(isSubmit){
      //isSubmit  是否提交参数
      //合并对象
 
      if(isSubmit){
        let params = {
          paperId: this.paperId,
          paperName: this.paperInfo.paperName
        }
        // this.showEditPaperName = !this.showEditPaperName
        this.$post("/biz/exam/bank/paper/modify", params,3000,true,6).then(() => {
          this.$message({
            type: "success",
            message: "修改成功!",
          });

          this.showEditPaperName = !this.showEditPaperName

          this.getPaperInfo();
        });
      }else{
        this.showEditPaperName = !this.showEditPaperName
      }

    },
    // 展示隐藏试卷编码编辑
    showHidePaperCodeEdit(isSubmit){
      //isSubmit  是否提交参数
      if(isSubmit){
        let params = {
          paperId: this.paperId,
          paperCode: this.paperInfo.paperCode
        }
        this.$post("/biz/exam/bank/paper/modify", params, 3000, true, 6).then(res => {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.showEditPaperCode = !this.showEditPaperCode
          this.getPaperInfo();
        });
      }else{
        this.showEditPaperCode = !this.showEditPaperCode
      }

    },
    // 展示隐藏大题编辑
    showHideTitleEdit(i,isSubmit,partitionId){
      //isSubmit  是否提交参数
      //合并对象

      if(isSubmit){
        let params = {
          paperId: this.paperId,
          partitionName: this.paperInfo.paperPartitionList[i].partitionName,
          partitionId,
        }
        this.$post("/biz/exam/bank/paper/partition/modify", params,3000,true,6).then(() => {
          this.$message({
            type: "success",
            message: "修改成功!",
          });

          let showEditTitle = !this.paperInfo.paperPartitionList[i].showEditTitle
          let currentParams= Object.assign(this.paperInfo.paperPartitionList[i], {
            showEditTitle: showEditTitle
          });
          this.paperInfo.paperPartitionList[i] = currentParams;

          this.getPaperInfo();
        });
      }else{
        let showEditTitle = !this.paperInfo.paperPartitionList[i].showEditTitle
        let currentParams= Object.assign(this.paperInfo.paperPartitionList[i], {
          showEditTitle: showEditTitle
        });
        this.paperInfo.paperPartitionList[i] = currentParams;
      }

    },
    // 展示隐藏题目分数编辑 - 单选、多选、判断、填空、简答、计算、论述、案例分析
    showHideScoreEdit(indexOuter,index,isSubmit,paperQuestionId){
      //isSubmit  是否提交参数
      if(isSubmit){
        if( this.paperInfo.paperPartitionList[indexOuter].paperList[index].score > 100 ){
          this.$message.error("单题分数不能超过100分！");
          return;
        }
        let params = {
          paperId: this.paperId,
          score: this.paperInfo.paperPartitionList[indexOuter].paperList[index].score,
          paperQuestionId,
        }
        this.$post("/biz/exam/bank/paper/question/modifyScore", params,3000,true,6).then(() => {
          this.$message({
            type: "success",
            message: "修改成功!",
          });

          //合并对象
          let showEditScore = !this.paperInfo.paperPartitionList[indexOuter].paperList[index].showEditScore
          let currentParams= Object.assign(this.paperInfo.paperPartitionList[indexOuter].paperList[index], {
            showEditScore: showEditScore
          });
          this.paperInfo.paperPartitionList[indexOuter].paperList[index] = currentParams;

          this.getPaperInfo();
        });
      }else{
        //合并对象
        let showEditScore = !this.paperInfo.paperPartitionList[indexOuter].paperList[index].showEditScore
        let currentParams= Object.assign(this.paperInfo.paperPartitionList[indexOuter].paperList[index], {
          showEditScore: showEditScore
        });
        this.paperInfo.paperPartitionList[indexOuter].paperList[index] = currentParams;
      }
    },
    // 展示隐藏子题目分数编辑 - 案例分析、组合
    showHideScoreEditInner(indexOuter,index,indexInner,isSubmit,paperQuestionId){
      //isSubmit  是否提交参数
      // console.log(indexOuter,index,indexInner,isSubmit,paperQuestionId)
      if(isSubmit){
        if( this.paperInfo.paperPartitionList[indexOuter].paperList[index].childList[indexInner].score > 100 ){
          this.$message.error("单题分数不能超过100分！");
          return;
        }
        let params = {
          paperId: this.paperId,
          score: this.paperInfo.paperPartitionList[indexOuter].paperList[index].childList[indexInner].score,
          paperQuestionId,
        }
        this.$post("/biz/exam/bank/paper/question/modifyScore", params,3000,true,6).then(() => {
          this.$message({
            type: "success",
            message: "修改成功!",
          });

          //合并对象
          let showEditScore = !this.paperInfo.paperPartitionList[indexOuter].paperList[index].childList[indexInner].showEditScore
          let currentParams= Object.assign(this.paperInfo.paperPartitionList[indexOuter].paperList[index].childList[indexInner], {
            showEditScore: showEditScore
          });
          this.paperInfo.paperPartitionList[indexOuter].paperList[index].childList[indexInner] = currentParams;

          this.getPaperInfo();
        });
      }else{
        //合并对象
        let showEditScore = !this.paperInfo.paperPartitionList[indexOuter].paperList[index].childList[indexInner].showEditScore
        let currentParams= Object.assign(this.paperInfo.paperPartitionList[indexOuter].paperList[index].childList[indexInner], {
          showEditScore: showEditScore
        });
        this.paperInfo.paperPartitionList[indexOuter].paperList[index].childList[indexInner] = currentParams;
      }
    },
    // 自行组卷-新增大题-弹窗打开
    addBigQuestionShow(){
        this.dialogVisibleBigQuestion = true
    },
    // 自行组卷-新增大题-弹窗关闭
    handleCloseBigQuestion(){
        this.bigQuestionTitle = ''
        this.dialogVisibleBigQuestion = false
    },
      // 添加大题标题
      addBigQuestion(){
          if(!this.bigQuestionTitle){
              this.$message.error('请输入大题标题')
              return
          }
          let params = {
              paperId: this.paperId,
              partitionName: this.bigQuestionTitle,
              sortId: this.paperInfo.paperPartitionList.length + 1
          }
          this.$post("/biz/exam/bank/paper/partition/insert", params,3000,true,6).then(() => {
              this.$message({
                  type: "success",
                  message: "添加成功!",
              });
              this.handleCloseBigQuestion()
              this.getPaperInfo();
          });
      },
    // 获取试题详情
    getPaperInfo(){
      let params = {
        paperId: this.paperId
      }
      if(this.questionName){
        params.questionName = this.questionName
      }
      if(this.questionType){
        params.questionType = this.questionType
      }
      this.$post("/biz/exam/bank/paper/question/paperInfo", params, 3000, true, 6).then(
          (res) => {
            console.log(res.data)
            this.paperInfo.paperName = res.data.paperName || '';
            this.paperInfo.bankId = res.data.bankId || '';
            this.paperInfo.paperCode = res.data.paperCode || '';
            // 题目list
            this.paperInfo.paperPartitionList = []
            res.data.paperPartitionList.forEach((e,i)=>{
              e.showEditTitle = false
                if(e.paperList&&e.paperList.length){
                    e.paperList.forEach((eInner,iInner)=>{

                        if(eInner.questionType == '70'||eInner.questionType == '80'){
                            eInner.childList.forEach((eInner2,iInner2)=>{
                                eInner2.showEditScore = false
                            })
                        }else{
                            eInner.showEditScore = false
                        }
                    })
                }else{
                    e.paperList = []
                    e.questionNum = 0
                    e.questionScore = 0

                }

              this.paperInfo.paperPartitionList.push(e)
            })
            // console.log(this.paperInfo.paperPartitionList)
            // 锚点list
            this.paperInfo.partitionList = []
            res.data.partitionList.forEach((e,i)=>{
              e.active = false
              if(i == 0){
                e.active = true
              }
              this.paperInfo.partitionList.push(e)
            })

          }
      );
    },
    // 阿拉伯数字转中文数字
    changeNumToHan(num) {
      var arr1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
      var arr2 = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿']
      if (!num || isNaN(num)) return '零'
      var english = num.toString().split('')
      var result = ''
      for (var i = 0; i < english.length; i++) {
        var des_i = english.length - 1 - i// 倒序排列设值
        result = arr2[i] + result
        var arr1_index = english[des_i]
        result = arr1[arr1_index] + result
      }
      result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十') // 将【零千、零百】换成【零】 【十零】换成【十】
      result = result.replace(/零+/g, '零') // 合并中间多个零为一个零
      result = result.replace(/零亿/g, '亿').replace(/零万/g, '万') // 将【零亿】换成【亿】【零万】换成【万】
      result = result.replace(/亿万/g, '亿') // 将【亿万】换成【亿】
      result = result.replace(/零+$/, '') // 移除末尾的零
      // 将【一十】换成【十】
      result = result.replace(/^一十/g, '十')
      return result
    },



    // 左侧大题锚点定位位置
    onTouch(id) {
      const sixteen = document.getElementById(id)
      sixteen.scrollIntoView({
        behavior: 'smooth' // 让滚动更丝滑
      }) // 你也可以设置丝滑滚动
    }
  },
};
</script>
<style lang="less">
.questionInner{
  //答题题目有表格的样式重置一下
  table {
    min-height: 25px;
    line-height: 25px;
    text-align: center;
    border-collapse: collapse;
    font-size: 12px;
  }

  table,
  table tr th,
  table tr td {
    border: 1px solid #a8aeb2;
    padding: 2px 4px;
  }
}
</style>

<style lang="less" scoped>
.ovy-a {
  display: flex;
  position: relative;

  .tacticsRule_left {
    width: 150px;

    .shell {
      position: fixed;
      line-height: 30px;
      overflow-y: scroll;
      height: 81%;
      width: 150px;
      .shellItem{
        user-select:none;
        background: #fafafa;
        padding-bottom: 10px;
        margin-bottom: 10px;
        margin-right: 0.675rem;
        cursor: pointer;
        border-radius: 4px;
        overflow: hidden;
        border: 1px solid #ecf5ff;
        transition: 0.5s;
        &.shellItem-active{
          border: 1px solid #2878ff;
          .shellTitle{
            background: #2878ff;
            color: #fff;
          }
          p{
            color: #2878ff;
          }
        }
        .shellTitle{
          font-size: 16px;
          line-height: 1.2;
          padding: 6px;
          margin-bottom: 8px;
          transition: 0.5s;
          background: #ecf5ff;
          color: #2878ff;
          text-align: center;
        }
        p{
          transition: 0.5s;
          font-size: 14px;
          line-height: 1.2;
          padding: 2px 6px;
        }
        &:hover{
          border: 1px solid #2878ff;
          .shellTitle{
            background: #2878ff;
            color: #fff;
          }
          p{
            color: #2878ff;
          }
        }
      }
    }
  }

  .tacticsRule_right {
    flex: 1;
    padding-left: 20px;
    width: 80%;

    // overflow-x: scroll;
    .el-form {
      width: 500px;

      .jbxx {
        /deep/ .el-form-item__label {
          font-size: 16px;
          font-weight: 600;
        }

      }
    }

  }

  .operateDiv {
    position: absolute;
    top: 0;
    right: 20px;
  }
}

/deep/ .el-table__fixed-right,
/deep/ .el-table .el-table__fixed {
  height: 100% !important;
}




//题目样式
.questionBox > div{
  margin-bottom: 15px;
  &:last-child{
    margin-bottom: 0;
  }
}
//.questionItem{
//  margin-bottom: 15px;
//  &:last-child{
//    margin-bottom: 0;
//  }
//}
.questionInner{
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2878FF;
  transition: 0.3s;
  &:hover{
    background: #ecf5ff;
  }
}
.questionInnerLeft{
  position: relative;
  width: 100%;
}
.questionTitle{
  line-height: 1.5;
  font-size: 16px;
  color: #333;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.questionType{
  white-space:nowrap;
  border-radius: 4px;
  margin-right: 6px;
  padding: 2px 8px;
  font-size: 14px;
  color: #2878ff;
  background: #fff;
  border: 1px solid #2878ff;
}
.questionOption{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  margin-bottom: 6px;
  span{
    width: 25%;
    padding-right: 8px;
    margin-bottom: 10px;

  }
}
.questionAnswer{
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  margin-bottom: 16px;
  width: 100%;
  &>span{
    width: 100%;
    border-radius: 4px;
    padding: 4px 8px;
    color: #67c23a;
    background: #f0f9eb;
    border-color: #c2e7b0;
  }
  .questionAnswerS{
    margin-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(133,206,97,0.3);
    &:last-child{
      border: 0;
    }
  }
}
.questionAnalysis{
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  width: 100%;
  &>span{
    width: 100%;
    border-radius: 4px;
    padding: 4px 8px;
    color: #e6a23c;
    background: #fdf6ec;
    border-color: #f5dab1;
  }
}
.questionInnerRight{
  min-width: 156px;
  max-width: 156px;
  margin-left: 15px;
  display: flex;
  flex-direction:column;
  &>div{
    &:nth-child(1){
      flex-grow: 1;
    }
    &:nth-child(2){
      flex-shrink: 0;
    }
  }

}
.showChildrenQuestion{
  cursor: pointer;
  color: #2878ff;
}
.childrenQuestionItem {
  position: relative;
  margin-top: 15px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2878FF;
  margin-right: -169px;
  .questionTitle,.questionAnswer,.questionAnalysis,.questionOption{
    width: calc(100% - 149px);
  }
  .mt16{
    position: absolute;
    width: 149px;
    top: 0;
    right: 0;
  }
  //&:last-child{
  //  margin-bottom: 0;
  //}
}
/*试卷样式*/
.paperBox{
  max-height: calc(100vh - 255px);
  overflow-y: scroll;
  .paperItem{
    padding: 20px 0;
    .paperHeader{
      padding: 6px 12px;
      background: #eee;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      &>div{
        margin-left: 10px;
        min-width: 92px;
      }
    }
  }
  /deep/.el-input__suffix{
    display: flex;
    align-items: center;
  }

  /deep/.el-input__inner{
    width: 320px;
    padding-right: 48px;
  }
  .questionInnerRight{
    /deep/.el-input--small{
      width: 100%;
    }
    /deep/.el-input__inner{
      width: 100%;
      padding-right: 15px;
    }
  }
}
.mt16{
  margin-top: 16px;
  margin-left: 8px;
  /deep/.el-input__inner{
    width: 88px!important;
    padding-right: 15px!important;
  }
}
//强制换行
.hh{
  word-break: break-all;
  word-wrap: break-word;
  //white-space: pre-wrap;
}
/deep/.operationControl .el-input .el-input__suffix{
  display: flex;
  align-items: center;
}
/deep/.operationControl .el-input input{
  width: 100%;
}
/deep/.el-button--danger:focus, .el-button--danger:hover{
  background: #F56C6C;
  border-color: #F56C6C;
}
.addBigQuestion /deep/.el-input .el-input__count{
  display: inline-block;
}
.addBigQuestion /deep/.el-input .el-input__count .el-input__count-inner{
  width: auto!important;
  min-width: auto!important;
  padding: 0;
}
.addBigQuestion /deep/.el-input .el-input__suffix-inner{
  min-width: auto!important;
  padding: 0;
}
.addBigQuestion /deep/.el-input input{
  padding-right: 55px;
}
///deep/.addBigQuestion .operationControl > div > div > span, /deep/.addBigQuestion .operationControl > div span > span{
//  min-width: auto!important;
//}
</style>